<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div class="dal-compfloat">
    <div class="compfloat">
      <div class="nb-nodeboard-top">
        <div class="nb-head-title">留言板</div>
        <button
          type="button"
          :class="showcompfloat ? 'nb-nodeboard-close' : 'nb-nodeboard-open'"
          @click="showcompfloat = !showcompfloat"
        ></button>
      </div>
      <div v-if="showcompfloat && !sendsuccess">
        <div class="nb_nodeboard_text">
          <div class="nb-nodeboard">
            <div class="nb-nodeboard-company">武汉数字智能</div>
            <div class="nb-nodeboard-TaaS">TaaS on Daline</div>
          </div>
          <el-button type="text" @click="showQrcode=true">

          </el-button>
          <img src="../assets/img-c33007da.png" class="c33007xiao"  @click="showQrcode=true"/>
        </div>
        <div class="nb-nodeboard-massege">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请在此输入留言内容，我们会尽快与您联系。(必填)"
            v-model="usermessage"
            maxlength="100"
            show-word-limit
            clearable
            resize="none"
            @input="onchangeusermessage"
          >
          </el-input>
          <!-- <textarea
          style="resize: none"
          name=""
          class="message"
          placeholder="请在此输入留言内容，我们会尽快与您联系。(必填)"
          cols="30"
          rows="10"
          @input="onchangeusermessage"
          v-model="usermessage"
        ></textarea> -->
          <div v-show="messagetip" class="message-tip">留言内容不可为空</div>
        </div>
        <div class="nb-nodeboard-name">
          <div class="name-icon"></div>
          <input
            type="text"
            placeholder="姓名(必填)"
            v-model="username"
            @input="onchangename"
            style="line-height: 45px"
          />
          <div v-show="nametip" class="nametip">姓名不能为空</div>
        </div>
        <div class="nb_nodeboard_phone">
          <div class="mobile-icon"></div>
          <input
            type="text"
            placeholder="电话(必填)"
            v-model="usermobile"
            @input="onchangephone"
            style="line-height: 45px"
          />
          <div v-show="phonetip" class="nametip">请输入正确格式的手机号码</div>
        </div>
        <div>
          <button @click="clicka6044c77()" type="button" class="sendmessage">
            发送
          </button>
        </div>
      </div>

      <div class="flex-col-center" v-if="showcompfloat && sendsuccess">
        <div class="successtip">
          <p>感谢留言</p>
          <p>我们会尽快与您联系</p>
        </div>
        <button @click="closetip" type="button" class="close-tip">关闭</button>
      </div>
    </div>
     <el-dialog
        title=""
        :visible.sync="showQrcode"
        width="20%"
        z-index=1
        :show-close="false"
        :center="true"
        custom-class="bigqrcode" >
        <span >
          <img src="../assets/img-a739757d.png" />
        </span>
      </el-dialog>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import { DALINEWEB_ARTICLECENTER_V0X0X1_sendSMSMessage } from "../api/dalapi.js";
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default {
  data() {
    return {
      showQrcode: false,
      //在此注释下方添加自定义变量
      showcompfloat: true,
      usermessage: "",
      username: "",
      usermobile: "",
      nametip: false,
      phonetip: false,
      messagetip: false,
      closecompfloat: false,
      sendsuccess: false,
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async clickafc043a9() {
      this.showQrcode = true;
    },
    //在此注释下方添加自定义函数或事件
    onchangename(e) {
      if (e.data) {
        this.nametip = false;
      }
    },
    onchangephone(e) {
      if (e.data) {
        this.phonetip = false;
      }
    },
    onchangeusermessage(e) {
      console.log(e);
      if (e) {
        this.messagetip = false;
      }
    },
    async closeboard() {
      this.showcompfloat = false;
      // this.closecompfloat = true;
    },
    async openboard() {
      this.showcompfloat = true;
      this.closecompfloat = false;
    },
    async closetip() {
      this.showcompfloat = false;
      this.sendsuccess = false;
      this.usermessage = ''
      this.username = ''
      this.usermobile = ''
    },
    async clicka6044c77() {
      let dicinput = {};
      if (this.usermessage) {
        dicinput.message = this.usermessage;
      } else {
        this.messagetip = true;
        return;
      }
      if (this.username) {
        dicinput.name = this.username;
      } else {
        this.nametip = true;
        return;
      }
      if (this.usermobile) {
        dicinput.telephone = this.usermobile;
      } else {
        this.phonetip = true;
        return;
      }
      if (!/^1[34578]\d{9}$/.test(this.usermobile)) {
        this.phonetip = true;
        return;
      }
      console.log(DALINEWEB_ARTICLECENTER_V0X0X1_sendSMSMessage);
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_sendSMSMessage(dicinput);
      if (
        res.retCode == 0 &&
        res.content.retCode == 0
      ) {
      this.sendsuccess = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
.dal-compfloat{
/deep/ .el-dialog__wrapper{
  .el-dialog__body{
    text-align: center;
    padding: 25px 25px 25px;
  }
  .el-dialog__header{
    padding: 0;
  }
}
}
.dal-compfloat {
  /deep/ .el-textarea__inner {
    padding: 16px;
  }
  position: fixed;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  .compfloat {
    // position: fixed;
    // right: 20px;
    // bottom: 10px;
    // top: auto;
    // left: auto;
    // padding: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    border-radius: 5px 5px 5px 5px;
    width: 350px;
    // height: 511px;
    background-color: #fff;
  }
  .closecompfloat {
    position: fixed;
    right: 20px;
    bottom: 10px;
    top: auto;
    left: auto;
    padding: 0;
    z-index: 10;
    display: flex;
    border-radius: 5px 5px 5px 5px;
    width: 350px;
    font-size: 24px;
    background-color: rgb(90, 90, 252);
    height: 90.5px;
  }
  .send-success {
    position: fixed;
    right: 20px;
    bottom: 10px;
    top: auto;
    left: auto;
    padding: 0;
    z-index: 10;
    border-radius: 5px 5px 5px 5px;
    width: 350px;
    font-size: 24px;
    background-color: #fff;
    height: 511px;
    .successtip {
      margin: auto;
      font-family: PingFang SC;
      padding: 60px 60px 60px 64px;
      p {
        font-size: 24px;
        line-height: 48px;
        text-align: center;
      }
    }
  }
}

.nb-nodeboard-top {
  height: 90.5px;
  width: 350px;
  font-size: 24px;
  background-color: rgb(90, 90, 252);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.nb-head-title {
  float: left;
  font-size: 24px;
  color: #fff;
  margin-left: 18px;
  line-height: 90px;
  padding-left: 5px;
}
.nb-nodeboard-close {
  float: right;
  height: 46px;
  width: 34px;
  cursor: pointer;
  background: transparent
    url(//sgoutong.baidu.com/embed/1631183588/asset/embed/css/pc/message/img/nodeMin.png)
    no-repeat;
  margin: 36px 12px 36px 22px;
}
.nb-nodeboard-open {
  float: right;
  height: 46px;
  width: 34px;
  cursor: pointer;
  background: transparent
    url("//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/nodeMax.png")
    no-repeat;
  margin: 30px 15px 36px 22px;
}
.nb_nodeboard_text {
  height: 70px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  padding-left: 25px;
}
.nb-nodeboard-massege {
  margin: 0 29px 10px 29px;
}
.nb-nodeboard {
  font-size: 26px;
  font-family: PingFang SC;
  line-height: 36px;
}
.nb-nodeboard-TaaS {
  font-size: 19px;
  font-family: PingFang SC;
  color: rgb(111, 116, 132);
}
.c33007xiao {
  height: 100%;
  padding-left: 70px;
}
.message {
  width: 290px;
  height: 120px;
  border: solid 0.5px rgb(221, 221, 221);

  font-size: 16px;
  border-radius: 8px;
  // color: rgb(221,221,221);
}
.nametip {
  color: red;
  font-size: 10px;
}
.nb-nodeboard-name {
  width: 290px;
  height: 45px;
  border: solid 0.5px;
  color: rgb(221, 221, 221);
  margin: 0 29px 20px 29px;
  border-radius: 5px;
}
.message-tip {
  color: red;
  font-size: 10px;
}
.name-icon {
  width: 35px;
  height: 45px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  vertical-align: top;
  border-right: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  background: #fff url("../assets/yonghu.png") no-repeat 9px center;
}
.mobile-icon {
  width: 35px;
  height: 45px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  vertical-align: top;
  border-right: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  background: #fff url("../assets/dianhua.png") no-repeat 9px center;
}
input::placeholder {
  font-size: 16px;
  color: rgb(221, 221, 221);
  line-height: 45px;
}
textarea::placeholder {
  font-size: 16px;
  color: rgb(221, 221, 221);
  line-height: 25px;
  padding: 15px;
}
.nb_nodeboard_phone {
  width: 290px;
  height: 45px;
  border: solid 0.5px;
  color: rgb(221, 221, 221);
  margin: 0 29px 10px 29px;
  border-radius: 5px;
}
#dlgfa3caa332 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.sendmessage {
  margin: 12px 29px 20px;
  float: right;
  font-size: 20px;
  color: rgb(255, 255, 255);
  width: 90px;
  height: 40px;
  background-color: rgb(90, 90, 252);
  border-radius: 5px 5px 5px 5px;
  // position: fixed;
  // right: 48px;
  cursor: pointer;
}
.close-tip {
  margin-top: 20px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  width: 90px;
  height: 40px;
  background-color: rgb(90, 90, 252);
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
}
/* .img-wrapper {
  padding: 0 20px;
  overflow: hidden;
  flex: 1;
}

.img-wrapper img {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 0;
  cursor: pointer;
}

.img-wrapper + .img-wrapper img {
  border-top: 2px solid gray;
}

#afc043a9 {
  width: 96px;
  position: relative;
  padding-left: 27px;
  padding-top: 30px;
}
#d50c13f5 {
  width: 96px;
  height: 128px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#d50c13f5 {
  vertical-align: middle;
}
#ab4514c1 {
  width: 96px;
  height: 1px;
  margin-top: 20px;
  padding-left: 28px;
}
#ab4514c1 {
  vertical-align: middle;
}
#a2d13ec7 {
  width: 86px;
  height: 128px;
  margin-top: 20px;
  padding-left: 32px;
}
#a2d13ec7 {
  vertical-align: middle;
}
#a7b205af {
  width: 96px;
  height: 1px;
  margin-top: 20px;
  padding-left: 28px;
}
#a7b205af {
  vertical-align: middle;
}
#a6044c77 {
  width: 96px;
  height: 128px;
  margin-top: 20px;
  padding-left: 27px;
}
#a6044c77 {
  vertical-align: middle;
}
#a29af77e {
  width: 96px;
  height: 1px;
  margin-top: 20px;
  padding-left: 28px;
}
#a29af77e {
  vertical-align: middle;
}
#c2a45297 {
  width: 96px;
  height: 122px;
  margin-top: 20px;
  padding-left: 27px;
  padding-bottom: 30px;
}
#c2a45297 {
  vertical-align: middle;
}


 */
/*在此注释下方添加自定义样式*/

#a3caa332 {
  width: 400px;
  height: 400px;
  border-radius: 4px 4px 4px 4px;
  background: #fff;
}
#a3caa332 img {
  width: 100%;
  height: 100%;
}
#dlgfafc043a9 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 500px;
  background-color: #fff;
}
.flex-col-center{
  min-height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p{
    line-height: 1.5em;
  }
}
</style>
