<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="article" style="width:100vw">
    <div id="a8983476" class="ql-editor" v-html="articleContent"></div>
    <div id="a4e34106">
      <dal-compfloat id="cmpa4e34106"> </dal-compfloat>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
import { DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo } from '../api/dalapi.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    'dal-compfloat': dal_compfloat
  },
  data () {
    return {
      //在此注释下方添加自定义变量
      articleID: this.$route.query.articleID,
      articleType:this.$route.query.articleType,
      minHeight: 0,
      articleInfo: {

      },
      articleContent: ''
    }
  },
  mounted () {
    console.log(this.articleType);
    this.getarticleinfo()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //在此注释下方添加自定义函数或事件
    async getarticleinfo () {
      let dicinput = {
        articleID: this.articleID
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo(dicinput)
      const articleInfo = res.content.resContent[0]
      const articleContent = articleInfo.articleContent
      this.articleContent = articleContent.replace("<img", `<img style="display: block; margin: auto; "`)
      this.$nextTick(() => {
        const imgs = document.querySelectorAll('.ql-editor img')
        imgs.forEach(p => {
          p.style.display = 'block'
          p.style.margin = 'auto'
        })
         const html =document.querySelector('.ql-editor');

          html.style.fontSize = document.documentElement.clientWidth/120+'px';
      })
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
  -o-object-fit: fill;
  object-fit: fill;
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#dc801d5b {
  width: 100vw;
  height: 50vh;
  position: relative;
}
/* #dc801d5b {
  background-image: url("../assets/img-a4fc0829.png");
  background-size: cover;
} */
#ac7a16f1 {
  width: 1218px;
  position: relative;
  margin-top: 100px;
  padding-left: 351px;
}
/* #a76bf198 {
  display: inline-block;
  vertical-align: middle;
}
#a76bf198 {
  text-align: center;
  color: #1c1c1c;
  font-size: 45px;
  font-family: PingFang SC;
} */
/* #b46a90b9 {
  padding-top: 33px;
  display: inline-block;
  vertical-align: middle;
}
#b46a90b9 {
  text-align: left;
  color: #9f9f9f;
  font-size: 22px;
  font-family: PingFang SC;
} */
#d41e0dd2 {
  width: 1218px;
  position: relative;
  margin-top: 60px;
  padding-left: 351px;
}
/* #e963beb9 {
  width: 1218px;
  height: 187px;
}
#e963beb9 {
  text-align: left;
  color: #9f9f9f;
  font-size: 30px;
  font-family: PingFang SC;
} */
#adeefc33 {
  width: 1218px;
  height: 536px;
  margin-top: 27px;
  padding-left: 351px;
}
#adeefc33 {
  vertical-align: middle;
}
/* #be3d22c0 {
  width: 1218px;
  position: relative;
  margin-top: 61px;
  padding-left: 351px;
} */
#a8983476 {
  width: 60vw;
  margin: auto;
  padding-top: 100px;
  /*height:187px;*/
  /* display:flex;
  flex-direction:column */
}
#a8983476 {
  color: #000;
  /* font-size: 21px; */
  font: normal 18px/200% "微软雅黑";
}
#a79cc578 {
  width: 1218px;
  height: 536px;
  margin-top: 60px;
  padding-left: 351px;
}
#a79cc578 {
  vertical-align: middle;
}
#d4b3277b {
  width: 1218px;
  position: relative;
  margin-top: 61px;
  padding-left: 351px;
  padding-bottom: 370px;
}
#a62cba1f {
  width: 1218px;
  height: 501px;
}
#a62cba1f {
  text-align: left;
  color: #9f9f9f;
  font-size: 30px;
  font-family: PingFang SC;
}
#a4e34106 {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 2081px;
  left: 1740px;
}
#a4e34106 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
#adeefc44 {
  width: 1218px;
  height: 536px;
  margin-top: 27px;
  padding-left: 351px;
}
#adeefc44 {
  vertical-align: middle;
}
/* .ql-video{
  margin:0 auto
  } */
.ql-editor .ql-video {
  /* display: block; */
  /* max-width: 100%; */
  margin: auto !important;
}

#article {
  padding-bottom: 4em;
}
</style>
